import { ApplicationRef, inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslocoService } from '@jsverse/transloco';
import {
	BehaviorSubject,
	catchError,
	concat,
	first,
	interval,
	of,
	switchMap,
	tap,
} from 'rxjs';

const HOUR = 60 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class ServiceWorkerService {
	private readonly updates = inject(SwUpdate);
	private readonly appRef = inject(ApplicationRef);
	private readonly transloco = inject(TranslocoService);

	/**
	 * Will trigger every hour since the initialization of this component, until the destruction of the
	 * app. Since this component is used as the root it is a good source of truth as to the age of the app instance
	 */
	private readonly everyHour$ = interval(HOUR);
	private readonly isStable$ = this.appRef.isStable.pipe(
		first((isStable) => isStable === true),
	);

	readonly updateAvailable$ = new BehaviorSubject(false);
	readonly checkForUpdate$ = concat(this.isStable$, this.everyHour$).pipe(
		switchMap(() => {
			if (!this.updates.isEnabled) return of(false);
			return this.updates.checkForUpdate();
		}),
		catchError(() => of(false)),
		tap((updateFound) => {
			if (updateFound) {
				const confirmed = window.confirm(
					this.transloco.translate('app.alert.new-version'),
				);
				if (confirmed) this.activateNewVersion();
				else this.updateAvailable$.next(true);
			} else if (this.updateAvailable$.value === true) {
				this.updateAvailable$.next(false);
			}
		}),
	);

	activateNewVersion() {
		document.location.reload();
	}
}
