{
	"name": "@context/source",
	"version": "1.0.3",
	"license": "MIT",
	"scripts": {
		"nx": "npx nx",
		"start": "npx nx serve",
		"build": "npx nx build",
		"deploy": "npx firebase deploy --only hosting",
		"lint": "npx nx affected --target=lint",
		"lint:fix": "npx nx affected --target=lint --fix",
		"---- Dev ----": "",
		"start:dev": "npx nx serve web --configuration development",
		"build:dev": "npx nx build web --configuration development && npm run cap:sync",
		"deploy:dev": "npx firebase use dev && npm run build:dev && npm run deploy",
		"start:api:dev": "npx nx serve api --configuration development",
		"build:api:dev": "npx nx build api --configuration development",
		"deploy:api:dev": "npx firebase use dev && npm run build:api:dev && npx firebase deploy --only functions",
		"deploy:api:rules:dev": "npx firebase use dev && npx firebase deploy --only firestore:rules",
		"deploy:storage:cors:dev": "gsutil cors set cors.json gs://dev-papyrus.appspot.com",
		"deploy:storage:rules:dev": "npx firebase use dev && npx firebase deploy --only storage",
		"deploy:indexes:dev": "npx firebase use dev && npx firebase deploy --only firestore:indexes",
		"start:scripts:dev": "npx nx serve scripts --environment=development",
		"---- Prod ----": "",
		"start:prod": "npx nx serve web --configuration production",
		"build:prod": "npx nx build web --configuration production && npm run cap:sync && npm run sentry:sourcemaps",
		"deploy:prod": "npx firebase use prod && npm run build:prod && npm run deploy",
		"start:api:prod": "npx nx serve api --configuration production",
		"build:api:prod": "npx nx build api --configuration production",
		"deploy:api:prod": "npx firebase use prod && npm run build:api:prod && npx firebase deploy --only functions",
		"deploy:api:rules:prod": "npx firebase use prod && npx firebase deploy --only firestore:rules",
		"deploy:storage:cors:prod": "gsutil cors set cors.json gs://papyrus-6486f.appspot.com",
		"deploy:storage:rules:prod": "npx firebase use prod && npx firebase deploy --only storage",
		"deploy:indexes:prod": "npx firebase use prod && npx firebase deploy --only firestore:indexes",
		"start:scripts:prod": "npx nx serve scripts --environment=production",
		"---- MISC ----": "",
		"nx:reset": "npx nx reset --verbose",
		"nx:report": "npx nx report --verbose",
		"cap:sync": "npx cap sync web",
		"translations": "npx node tools/scripts/auto-order-translations.js",
		"sentry:sourcemaps": "sentry-cli sourcemaps inject --org vizashift --project context ./dist/apps/web/browser && sentry-cli sourcemaps upload --org vizashift --project context ./dist/apps/web/browser"
	},
	"engines": {
		"node": "20"
	},
	"private": true,
	"pre-commit": [
		"translations"
	],
	"dependencies": {
		"@algolia/client-search": "^5.12.0",
		"@angular/animations": "18.2.0",
		"@angular/cdk": "18.2.0",
		"@angular/common": "18.2.0",
		"@angular/compiler": "18.2.0",
		"@angular/core": "18.2.0",
		"@angular/fire": "^18.0.1",
		"@angular/forms": "18.2.0",
		"@angular/platform-browser": "18.2.0",
		"@angular/platform-browser-dynamic": "18.2.0",
		"@angular/router": "18.2.0",
		"@angular/service-worker": "18.2.0",
		"@capacitor/camera": "^6.0.2",
		"@capacitor/core": "^6.1.2",
		"@capacitor/haptics": "^6.0.1",
		"@capacitor/local-notifications": "^6.1.0",
		"@ionic/pwa-elements": "^3.3.0",
		"@jsverse/transloco": "^7.4.3",
		"@nestjs/common": "^10.0.2",
		"@nestjs/core": "^10.0.2",
		"@nestjs/platform-express": "^10.0.2",
		"@nx/angular": "20.0.3",
		"@sendgrid/mail": "^8.1.3",
		"@sentry/angular": "^8.20.0",
		"@taiga-ui/addon-mobile": "4.11.0",
		"@taiga-ui/cdk": "4.11.0",
		"@taiga-ui/core": "4.11.0",
		"@taiga-ui/icons": "4.11.0",
		"@taiga-ui/kit": "4.11.0",
		"@taiga-ui/layout": "4.11.0",
		"@taiga-ui/legacy": "4.11.0",
		"@taiga-ui/polymorpheus": "^4.7.4",
		"@taiga-ui/styles": "4.11.0",
		"@tinkoff/ng-polymorpheus": "^4.3.0",
		"@types/ua-parser-js": "^0.7.39",
		"algoliasearch": "^5.12.0",
		"axios": "^1.6.0",
		"browser-image-compression": "^2.0.2",
		"firebase": "^10.12.4",
		"firebase-admin": "^12.3.0",
		"firebase-functions": "^6.0.1",
		"hammerjs": "^2.0.8",
		"jsonwebtoken": "^9.0.2",
		"mousetrap": "^1.6.5",
		"ngx-image-cropper": "^8.0.0",
		"ngx-quill": "^26.0.8",
		"nx-cloud": "19.1.0",
		"quill": "^2.0.2",
		"reflect-metadata": "^0.1.13",
		"rxjs": "~7.8.0",
		"string-format": "^2.0.0",
		"tslib": "^2.3.0",
		"ua-parser-js": "^1.0.38",
		"uuid": "^10.0.0",
		"zone.js": "~0.14.3"
	},
	"devDependencies": {
		"@angular-devkit/build-angular": "18.2.0",
		"@angular-devkit/core": "18.2.0",
		"@angular-devkit/schematics": "18.2.0",
		"@angular-eslint/eslint-plugin": "^18.0.1",
		"@angular-eslint/eslint-plugin-template": "^18.0.1",
		"@angular-eslint/template-parser": "^18.0.1",
		"@angular/cli": "~18.1.0",
		"@angular/compiler-cli": "18.2.0",
		"@angular/language-service": "18.2.0",
		"@angular/pwa": "18.2.0",
		"@capacitor/cli": "^6.1.2",
		"@eslint/eslintrc": "^2.1.1",
		"@eslint/js": "~8.57.0",
		"@nestjs/schematics": "^10.0.1",
		"@nestjs/testing": "^10.0.2",
		"@nx/devkit": "20.0.3",
		"@nx/esbuild": "20.0.3",
		"@nx/eslint": "20.0.3",
		"@nx/eslint-plugin": "20.0.3",
		"@nx/jest": "20.0.3",
		"@nx/js": "20.0.3",
		"@nx/nest": "20.0.3",
		"@nx/node": "20.0.3",
		"@nx/playwright": "20.0.3",
		"@nx/web": "20.0.3",
		"@nx/webpack": "20.0.3",
		"@nx/workspace": "20.0.3",
		"@playwright/test": "^1.36.0",
		"@schematics/angular": "18.2.0",
		"@sentry/cli": "^2.33.0",
		"@swc-node/register": "~1.9.1",
		"@swc/core": "~1.5.7",
		"@swc/helpers": "~0.5.11",
		"@types/jest": "29.5.12",
		"@types/mousetrap": "^1.6.15",
		"@types/node": "^20.0.0",
		"@types/string-format": "^2.0.3",
		"@types/uuid": "^10.0.0",
		"@typescript-eslint/eslint-plugin": "8.10.0",
		"@typescript-eslint/parser": "8.10.0",
		"@typescript-eslint/utils": "8.10.0",
		"angular-eslint": "^18.3.0",
		"esbuild": "^0.19.2",
		"eslint": "9.13.0",
		"eslint-config-prettier": "^9.0.0",
		"eslint-plugin-playwright": "^0.15.3",
		"firebase-tools": "^13.14.1",
		"jest": "29.7.0",
		"jest-environment-jsdom": "29.7.0",
		"jest-environment-node": "^29.7.0",
		"jest-preset-angular": "~14.1.0",
		"nx": "20.0.3",
		"pre-commit": "^1.2.2",
		"prettier": "^3.3.3",
		"ts-jest": "^29.1.0",
		"ts-node": "10.9.1",
		"typescript": "~5.5.2",
		"typescript-eslint": "^8.0.0",
		"webpack-cli": "^5.1.4"
	}
}
