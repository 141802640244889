import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import { applyValidators } from '@context/frontend/form';
import { Organization } from '@context/shared/types/organization';
import { getName, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiButton, TuiError, TuiTextfield } from '@taiga-ui/core';
import { TuiButtonLoading, TuiFieldErrorPipe } from '@taiga-ui/kit';
import {
	TuiTextareaModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { SupportService } from '../../services';
import { APP_VERSION, SUPPORT_EMAIL } from '../../tokens';
import {
	DialogBase,
	DialogOptions,
} from '../dialog-base/dialog-base.component';

@Component({
	standalone: true,
	imports: [
		TuiButton,
		TuiTextfield,
		FormsModule,
		ReactiveFormsModule,
		TranslocoModule,
		TuiError,
		TuiFieldErrorPipe,
		AsyncPipe,
		TuiTextfieldControllerModule,
		TuiTextareaModule,
		TuiButtonLoading,
	],
	selector: 'ctx-help-dialog',
	templateUrl: 'help-dialog.component.html',
})
export class HelpDialogComponent
	extends applyValidators(
		DialogBase<void, { user: User; organization: Organization }>,
	)
	implements OnInit
{
	static override readonly DialogOptions = {
		dismissible: false,
		label: 'support.help-header',
		size: 's',
	} as DialogOptions;

	readonly support = inject(SupportService);
	readonly alert = inject(AlertService);

	readonly supportEmail = inject(SUPPORT_EMAIL);
	readonly version = inject(APP_VERSION);

	form: FormGroup | null = null;
	busy = false;

	ngOnInit() {
		this.setupForm();
	}

	setupForm() {
		this.form = new FormGroup({
			organizationId: new FormControl(this.context.data.organization.id),
			userId: new FormControl(this.context.data.user.id),
			email: new FormControl(this.context.data.user.email),
			name: new FormControl(getName(this.context.data.user)),
			organizationName: new FormControl(
				this.context.data.organization.name,
			),
			message: new FormControl(null, (field) =>
				this.getRequiredValidator(field, 'form.error.message.required'),
			),
			version: new FormControl(this.version),
		});

		this.form.get('email')?.disable();
		this.form.get('name')?.disable();
		this.form.get('organizationName')?.disable();
		this.form.get('version')?.disable();
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;
		this.busy = true;

		this.form.disable();
		return this.support
			.help(this.form.getRawValue())
			.then(() => {
				this.alert.open('help-sent').subscribe();
				this.onDismiss();
			})
			.catch((error) => {
				this.alert.open('generic-error').subscribe();
				console.error(error);
				throw new Error(
					'There was an error submitting the inquiry form',
				);
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
				this.form?.get('email')?.disable();
				this.form?.get('name')?.disable();
				this.form?.get('organizationName')?.disable();
				this.form?.get('version')?.disable();
			});
	}
}
