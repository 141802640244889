<section tuiContent>
	<p
		class="margin-top-0 margin-bottom-4"
		[innerHTML]="
			'support.help-description' | transloco: { email: supportEmail }
		"
	></p>

	@if (form) {
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<label tuiLabel>
				{{ 'app.app-version' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input formControlName="version" tuiTextfield type="text" />
				</tui-textfield>
			</label>

			<label tuiLabel>
				{{ 'data.email' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="email"
						tuiTextfield
						type="email"
						autocomplete="email"
					/>
				</tui-textfield>
			</label>

			<label tuiLabel>
				{{ 'data.name' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="name"
						tuiTextfield
						autocomplete="name"
					/>
				</tui-textfield>
			</label>

			<label tuiLabel>
				{{ 'data.organization' | transloco }}

				<tui-textfield tuiTextfieldSize="m">
					<input
						formControlName="organizationName"
						tuiTextfield
						autocomplete="organizationName"
					/>
				</tui-textfield>
			</label>

			<label tuiLabel>
				{{ 'data.message' | transloco }}*

				<tui-textarea
					tuiTextfieldSize="m"
					formControlName="message"
					[tuiTextfieldLabelOutside]="true"
					[expandable]="true"
					[maxLength]="maxLength"
				></tui-textarea>

				<tui-error
					formControlName="message"
					[error]="[] | tuiFieldError | async"
				/>
			</label>
		</form>
	}
</section>

<footer tuiFooter>
	<button tuiButton appearance="accent" [loading]="busy" (click)="onSubmit()">
		{{ 'action.send' | transloco }}
	</button>
</footer>
