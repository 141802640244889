// builds/browser.ts
import { createXhrRequester } from "@algolia/requester-browser-xhr";
import { createBrowserLocalStorageCache, createFallbackableCache, createMemoryCache, createNullLogger } from "@algolia/client-common";

// src/abtestingClient.ts
import { createAuth, createTransporter, getAlgoliaAgent } from "@algolia/client-common";
var apiClientVersion = "5.18.0";
var REGIONS = ["de", "us"];
function getDefaultHosts(region) {
  const url = !region ? "analytics.algolia.com" : "analytics.{region}.algolia.com".replace("{region}", region);
  return [{
    url,
    accept: "readWrite",
    protocol: "https"
  }];
}
function createAbtestingClient({
  appId: appIdOption,
  apiKey: apiKeyOption,
  authMode,
  algoliaAgents,
  region: regionOption,
  ...options
}) {
  const auth = createAuth(appIdOption, apiKeyOption, authMode);
  const transporter = createTransporter({
    hosts: getDefaultHosts(regionOption),
    ...options,
    algoliaAgent: getAlgoliaAgent({
      algoliaAgents,
      client: "Abtesting",
      version: apiClientVersion
    }),
    baseHeaders: {
      "content-type": "text/plain",
      ...auth.headers(),
      ...options.baseHeaders
    },
    baseQueryParameters: {
      ...auth.queryParameters(),
      ...options.baseQueryParameters
    }
  });
  return {
    transporter,
    /**
     * The `appId` currently in use.
     */
    appId: appIdOption,
    /**
     * Clears the cache of the transporter for the `requestsCache` and `responsesCache` properties.
     */
    clearCache() {
      return Promise.all([transporter.requestsCache.clear(), transporter.responsesCache.clear()]).then(() => void 0);
    },
    /**
     * Get the value of the `algoliaAgent`, used by our libraries internally and telemetry system.
     */
    get _ua() {
      return transporter.algoliaAgent.value;
    },
    /**
     * Adds a `segment` to the `x-algolia-agent` sent with every requests.
     *
     * @param segment - The algolia agent (user-agent) segment to add.
     * @param version - The version of the agent.
     */
    addAlgoliaAgent(segment, version) {
      transporter.algoliaAgent.add({
        segment,
        version
      });
    },
    /**
     * Helper method to switch the API key used to authenticate the requests.
     *
     * @param params - Method params.
     * @param params.apiKey - The new API Key to use.
     */
    setClientApiKey({
      apiKey
    }) {
      if (!authMode || authMode === "WithinHeaders") {
        transporter.baseHeaders["x-algolia-api-key"] = apiKey;
      } else {
        transporter.baseQueryParameters["x-algolia-api-key"] = apiKey;
      }
    },
    /**
     * Creates a new A/B test.
     *
     * Required API Key ACLs:
     *  - editSettings
     * @param addABTestsRequest - The addABTestsRequest object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    addABTests(addABTestsRequest, requestOptions) {
      if (!addABTestsRequest) {
        throw new Error("Parameter `addABTestsRequest` is required when calling `addABTests`.");
      }
      if (!addABTestsRequest.name) {
        throw new Error("Parameter `addABTestsRequest.name` is required when calling `addABTests`.");
      }
      if (!addABTestsRequest.variants) {
        throw new Error("Parameter `addABTestsRequest.variants` is required when calling `addABTests`.");
      }
      if (!addABTestsRequest.endAt) {
        throw new Error("Parameter `addABTestsRequest.endAt` is required when calling `addABTests`.");
      }
      const requestPath = "/2/abtests";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: addABTestsRequest
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customDelete - The customDelete object.
     * @param customDelete.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customDelete.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customDelete({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customDelete`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customGet - The customGet object.
     * @param customGet.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customGet.parameters - Query parameters to apply to the current query.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customGet({
      path,
      parameters
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customGet`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPost - The customPost object.
     * @param customPost.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPost.parameters - Query parameters to apply to the current query.
     * @param customPost.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPost({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPost`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * This method allow you to send requests to the Algolia REST API.
     * @param customPut - The customPut object.
     * @param customPut.path - Path of the endpoint, anything after \"/1\" must be specified.
     * @param customPut.parameters - Query parameters to apply to the current query.
     * @param customPut.body - Parameters to send with the custom request.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    customPut({
      path,
      parameters,
      body
    }, requestOptions) {
      if (!path) {
        throw new Error("Parameter `path` is required when calling `customPut`.");
      }
      const requestPath = "/{path}".replace("{path}", path);
      const headers = {};
      const queryParameters = parameters ? parameters : {};
      const request = {
        method: "PUT",
        path: requestPath,
        queryParameters,
        headers,
        data: body ? body : {}
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Deletes an A/B test by its ID.
     *
     * Required API Key ACLs:
     *  - editSettings
     * @param deleteABTest - The deleteABTest object.
     * @param deleteABTest.id - Unique A/B test identifier.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    deleteABTest({
      id
    }, requestOptions) {
      if (!id) {
        throw new Error("Parameter `id` is required when calling `deleteABTest`.");
      }
      const requestPath = "/2/abtests/{id}".replace("{id}", encodeURIComponent(id));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "DELETE",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Given the traffic percentage and the expected effect size, this endpoint estimates the sample size and duration of an A/B test based on historical traffic.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param estimateABTestRequest - The estimateABTestRequest object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    estimateABTest(estimateABTestRequest, requestOptions) {
      if (!estimateABTestRequest) {
        throw new Error("Parameter `estimateABTestRequest` is required when calling `estimateABTest`.");
      }
      if (!estimateABTestRequest.configuration) {
        throw new Error("Parameter `estimateABTestRequest.configuration` is required when calling `estimateABTest`.");
      }
      if (!estimateABTestRequest.variants) {
        throw new Error("Parameter `estimateABTestRequest.variants` is required when calling `estimateABTest`.");
      }
      const requestPath = "/2/abtests/estimate";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: estimateABTestRequest
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Retrieves the details for an A/B test by its ID.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param getABTest - The getABTest object.
     * @param getABTest.id - Unique A/B test identifier.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    getABTest({
      id
    }, requestOptions) {
      if (!id) {
        throw new Error("Parameter `id` is required when calling `getABTest`.");
      }
      const requestPath = "/2/abtests/{id}".replace("{id}", encodeURIComponent(id));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Lists all A/B tests you configured for this application.
     *
     * Required API Key ACLs:
     *  - analytics
     * @param listABTests - The listABTests object.
     * @param listABTests.offset - Position of the first item to return.
     * @param listABTests.limit - Number of items to return.
     * @param listABTests.indexPrefix - Index name prefix. Only A/B tests for indices starting with this string are included in the response.
     * @param listABTests.indexSuffix - Index name suffix. Only A/B tests for indices ending with this string are included in the response.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    listABTests({
      offset,
      limit,
      indexPrefix,
      indexSuffix
    } = {}, requestOptions = void 0) {
      const requestPath = "/2/abtests";
      const headers = {};
      const queryParameters = {};
      if (offset !== void 0) {
        queryParameters["offset"] = offset.toString();
      }
      if (limit !== void 0) {
        queryParameters["limit"] = limit.toString();
      }
      if (indexPrefix !== void 0) {
        queryParameters["indexPrefix"] = indexPrefix.toString();
      }
      if (indexSuffix !== void 0) {
        queryParameters["indexSuffix"] = indexSuffix.toString();
      }
      const request = {
        method: "GET",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Schedule an A/B test to be started at a later time.
     *
     * Required API Key ACLs:
     *  - editSettings
     * @param scheduleABTestsRequest - The scheduleABTestsRequest object.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    scheduleABTest(scheduleABTestsRequest, requestOptions) {
      if (!scheduleABTestsRequest) {
        throw new Error("Parameter `scheduleABTestsRequest` is required when calling `scheduleABTest`.");
      }
      if (!scheduleABTestsRequest.name) {
        throw new Error("Parameter `scheduleABTestsRequest.name` is required when calling `scheduleABTest`.");
      }
      if (!scheduleABTestsRequest.variants) {
        throw new Error("Parameter `scheduleABTestsRequest.variants` is required when calling `scheduleABTest`.");
      }
      if (!scheduleABTestsRequest.scheduledAt) {
        throw new Error("Parameter `scheduleABTestsRequest.scheduledAt` is required when calling `scheduleABTest`.");
      }
      if (!scheduleABTestsRequest.endAt) {
        throw new Error("Parameter `scheduleABTestsRequest.endAt` is required when calling `scheduleABTest`.");
      }
      const requestPath = "/2/abtests/schedule";
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers,
        data: scheduleABTestsRequest
      };
      return transporter.request(request, requestOptions);
    },
    /**
     * Stops an A/B test by its ID.  You can\'t restart stopped A/B tests.
     *
     * Required API Key ACLs:
     *  - editSettings
     * @param stopABTest - The stopABTest object.
     * @param stopABTest.id - Unique A/B test identifier.
     * @param requestOptions - The requestOptions to send along with the query, they will be merged with the transporter requestOptions.
     */
    stopABTest({
      id
    }, requestOptions) {
      if (!id) {
        throw new Error("Parameter `id` is required when calling `stopABTest`.");
      }
      const requestPath = "/2/abtests/{id}/stop".replace("{id}", encodeURIComponent(id));
      const headers = {};
      const queryParameters = {};
      const request = {
        method: "POST",
        path: requestPath,
        queryParameters,
        headers
      };
      return transporter.request(request, requestOptions);
    }
  };
}

// builds/browser.ts
function abtestingClient(appId, apiKey, region, options) {
  if (!appId || typeof appId !== "string") {
    throw new Error("`appId` is missing.");
  }
  if (!apiKey || typeof apiKey !== "string") {
    throw new Error("`apiKey` is missing.");
  }
  if (region && (typeof region !== "string" || !REGIONS.includes(region))) {
    throw new Error(`\`region\` must be one of the following: ${REGIONS.join(", ")}`);
  }
  return createAbtestingClient({
    appId,
    apiKey,
    region,
    timeouts: {
      connect: 1e3,
      read: 2e3,
      write: 3e4
    },
    logger: createNullLogger(),
    requester: createXhrRequester(),
    algoliaAgents: [{
      segment: "Browser"
    }],
    authMode: "WithinQueryParameters",
    responsesCache: createMemoryCache(),
    requestsCache: createMemoryCache({
      serializable: false
    }),
    hostsCache: createFallbackableCache({
      caches: [createBrowserLocalStorageCache({
        key: `${apiClientVersion}-${appId}`
      }), createMemoryCache()]
    }),
    ...options
  });
}
export { abtestingClient, apiClientVersion };
